import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'
import { Link, useStaticQuery, graphql } from "gatsby"

import soru from "../images/soru.svg"
import { RiArrowRightSLine } from 'react-icons/ri';
import faq from "../images/faq.svg"


import '../styles/grid.scss'
import '../styles/index.scss'


const HelpPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata{
            email
        }
    }
    }
  `)

  return(
    <Layout>
      <Head title="Yardım" description="Altın günü modeliyle para biriktirme uygulaması Çember ile ilgili soruların için aşağıdaki Sık Sorulan Sorular’ı inceleyebilirsin." />
      <div className="row yardim">
       <div className="hero-container">
        <div className="container">
          <div className="row">
         
            <div className="col-6">
              <div className="hero-text">
                <h1>Nasıl Yardımcı Olabiliriz?</h1>
                <p>Çember ile ilgili soruların için aşağıdaki Sık Sorulan Sorular’ı inceleyebilirsin. </p>  
                <p>Uygulamada zorluklar yaşıyorsan iletişim kanallarımızdan da bize ulaşabilirsin. En kısa sürede geri döneriz.</p>  
                <p><Link to="/contact"> <RiArrowRightSLine size="30" style={{ 'color': "#F93666" }}/>  {data.site.siteMetadata.email} </Link></p>
                <h5>
                   <Link className="cta-button" to="/contact">İletişim formu</Link>
                </h5>        
              </div>
            </div>
            <div className="col-6 center">
              <img src={faq} alt="Sık Sorulan Sorular"/> 
            </div>
            
          </div>
        </div>  
      </div>
      </div>
      <div className="row faq">
        <div className="container">
          <div className="row section-title">
            <h1>Sık Sorulan Sorular <span role="img" aria-label="Emoji"> 🤔 </span> </h1>
          </div>
          <div className="row">
              <ul>
                <li>
                  <span>Çember nedir?</span>
                  <p>Çember, hayallerini finanse edebilmeni, borçlanmadan birikim ve harcama yapabilmeni sağlayan bir uygulamadır. Altın günü modeliyle yakınlarınla ortak bir hedef ve miktar belirleyerek her ay ödeme yaparsın ve her ay bir kişi biriken parayı alır.</p>
                </li>
                <li>
                  <span>Uygulamayı nasıl indirebilirim?</span>
                  <p>Çember’in mobil uygulamasını App Store veya Play Store üzerinden ücretsiz indirebilirsin.</p>
                </li>
                <li>
                  <span>Nasıl kullanılır?</span>
                  <p>Birlikte para biriktirmek istediğin kişileri oluşturduğun çembere davet ediyorsun, hedeflenen kişi sayısına ulaştığında çember başlıyor. Ödeme alma sırası sistem tarafından rastgele belirleniyor. Kime, ne zaman, ne kadar ödeme yapacağını çember detayında görebileceksin. Ayrıca sana bildirimlerle hatırlatacağız.</p>
                </li>
                <li>
                  <span>Kaç kişi ile çember oluşturabilirim?</span>
                  <p>En az 2, en fazla 10 kişi ile çember oluşturabilirsin.</p>
                </li>
                <li>
                  <span>Ödemeler otomatik mi gerçekleşiyor?</span>
                  <p>Katıldığın çemberde her katılımcının IBAN numarasını görürsün. 
                    Ödeme günü geldiğinde bu IBAN'ı kolayca kopyalarak kendi bankacılık uygulamandan arkadaşına para gönderebilirsin. 
                  <b>Çember herhangi bir şekilde hesabından para çekmez veya ödeme almaz.</b>
                  </p>
                </li>
                <li>
                  <span>Ödeme alma sırası neye göre belli oluyor?</span>
                  <p>Bulunduğun çember hedeflenen katılımcı sayısına ulaştığında, ödeme sıraları sistem tarafından tıpkı kura çeker gibi rastgele olarak belirlenir. 
                    Ödemeyi kimin hangi sırada ve tarihte alacağı çember detayında görülür. Unutma, ödemeyi ilk almak güzel olabilir ama son sırada olursan da gerçekten biriktirdiğin toplu bir miktara sahip olacaksın. </p>
                </li>
                <li>
                  <span>Biri paraları alıp kaçarsa ne oluyor?</span>
                  <p>Çember, arkadaşlarınla, güvendiğin kişilerle para biriktirmeni sağlamak için bir aracı. Bu sebeple şu an sadece senin davet ettiğin veya seni davet eden kişilerle çember oluşturabiliyorsun. </p>
                </li>
                <li>
                  <span>Keşfet’te gördüğüm çembelere katılabilir miyim?</span>
                  <p>Şu anda sadece tanıdıkların tarafından oluşturulmuş davet edildiğin çemberlere katılabilirsin. İleride Keşfet’te gördüklerine de katılabileceksin.</p>
                </li>
                <li>
                  <span>Ödeme almak için hangi banka hesaplarımı kullanabilirim?</span>
                  <p>İstediğin banka hesabını, banka adını seçip IBAN numaranı girerek tanımlayabilirsin. Ödeme zamanı 
          gelince çemberindeki arkadaşların IBAN numaranı kolayca kopyalayıp mobil bankacılıklarından ödeme yapabilirler.</p>
                </li>
                <li>
                  <span>Nasıl ödeme yaparım?</span>
                  <p>Ödeme günü geldiğinde bildirimlerin açıksa uygulama sana hatırlatma gönderir. 
                    Ödeme sırası gelen arkadaşın da hatırlatma yapabilir tabii  <span role="img" aria-label="emoji"  className="emoji">
                    🤓
                      </span>. Çember detayında ödeme yapacağın arkadaşının 
                    IBAN numarasını kopyalayıp kullandığın bankacılık uygulamasından gönderimini gerçekleştirebilirsin. 
                    Ödeme açıklamasını "Çember - Çember İsmi - Sıra/Ay" şeklinde yazmanı tavsiye ediyoruz. 
                    Örneğin. "Çember - Scooter Alıyoruz - 2. ay"</p>
                </li>
                <li>
                  <span>Nasıl ödeme alırım?</span>
                  <p>Ödeme sırası sana geldiğinde çember oluşturduğun kişiler çembere katılırken tanımladığın hesabına belirlenen miktarı gönderirler. Sıranın sende olduğu ay ödemeni alırsın. 
                  Ödeme aldıktan sonra, ödeme yapan arkadaşlarını "Çemberlerim" ekranından onaylamayı unutma.</p>
                </li>
                <li>
                  <span>Çember’i iptal edebilir miyim?</span>
                  <p>Oluşturduğun Çember’i iptal etmen şimdilik mümkün değil. Bu özelliği çok yakında devreye alacağız.</p>
                </li>
                <li>
                  <span>Çember’deki kişi sayısını değiştirebilir miyim?</span>
                  <p>Oluşturduğun Çember’de değişiklik yapman mümkün değil. Bu özelliği eklemek için çalışıyoruz.</p>
                </li>
                <li>
                  <span>Neden Çember ile para biriktirmeliyim?</span>
                  <p>Çember’de yakınlarınla ortak bir amaç için para biriktirebilir, tek başına sahip olmadığın birikim motivasyonunu seçtiğin kişilerle birlikte biriktirerek sağlayabilirsin. Hedeflediklerini borçlanmadan alabilirsin.</p>
                </li>
                <li>
                  <span>Euro veya dolar olarak çember oluşturabiliyor muyum?</span>
                  <p>Çember’e TL, Euro veya Dolar hesabını tanımlayabilirsin ve bu kurlardan biriyle para biriktirebilirsin.</p>
                </li>
                <li>
                  <span>Çember’de en fazla ne kadar para biriktirebilirim?</span>
                  <p>Aylık biriktirebileceğin miktar en fazla 500 TL, 500 € veya 500 $. Maksimum 10 kişi ile yani 10 ay birikim yapabiliyorsun. Matematiği sen yap. <span role="img" aria-label="angel">😇</span></p>
                </li>
                <li>
                  <span>Kayıt olduğumda herkes bilgilerimi görecek mi?</span>
                  <p>Kayıt olduğunda seninle aynı çemberde olmayan kişiler yalnızca ismini görebilirler. Adın, soyadın ve IBAN numaranı sadece aynı çembere katıldığın kişiler görebilir.</p>
                </li>
              </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="container">
          <div className="col-12">
            <div className="sorukutu">
              <h1>Aklında başka sorular varsa bize ulaş!</h1>
              <Link to="/contact"> <RiArrowRightSLine size="30" style={{ 'color': "#F93666" }}/> <span> Bize yaz </span> </Link>
              <img src={soru} alt="Soru Sor"/>
            </div>
          </div>
        </div>
    </div>

    </Layout>
  )
}

export default HelpPage